<template>
  <div>
    <v-card class="mx-10 cardCustome" outlined>
      <div class="d-flex justify-space-between">
        <div class="justify-content-between d-flex mr-4 w-50">
          <v-checkbox
            v-model="accountingChecked"
            label="経理チェック有りも表示"
            class="w-50"
          ></v-checkbox>
          <!-- Date picker -->
          <div class="d-flex align-center w-50">
            <div v-for="item in listDate" :key="item.id">
              <v-menu
                v-model="menuDate[listDate.indexOf(item)]"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <div class="d-flex justify-start">
                    <v-text-field
                      v-model="datafromDateSelected"
                      class="color-calender"
                      prepend-icon="mdi-calendar-month"
                      readonly
                      dense
                      clearable
                      hide-details
                      label="入金日"
                      v-on="on"
                      @click:clear="dataToDateSelected = null"
                    ></v-text-field>
                    <h5 class="mt-2 w-25">から</h5>
                  </div>
                </template>
                <!-- Date from -->
                <v-date-picker
                  v-model="datafromDateSelected"
                  :first-day-of-week="0"
                  :locale="$i18n.locale"
                  scrollable
                  @input="menuDate[listDate.indexOf(item)] = false"
                  class="v-date-picker-custom"
                ></v-date-picker>
                <!--  -->
              </v-menu>
            </div>
          </div>

          <div class="d-flex align-center w-50">
            <div v-for="item in listDateTo" :key="item.id">
              <v-menu
                v-model="menuDate[listDate.indexOf(item)]"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <div class="d-flex justify-start">
                    <v-text-field
                      v-model="dataToDateSelected"
                      class="color-calender"
                      prepend-icon="mdi-calendar-month"
                      readonly
                      dense
                      clearable
                      hide-details
                      label="入金日"
                      v-on="on"
                      @click:clear="dataToDateSelected = null"
                    ></v-text-field>
                    <h5 class="mt-2 w-25">まで</h5>
                  </div>
                </template>

                <!-- Date to -->
                <v-date-picker
                  v-model="dataToDateSelected"
                  :first-day-of-week="0"
                  :min="listDate[0].date"
                  :locale="$i18n.locale"
                  scrollable
                  @input="menuDateTo[listDate.indexOf(item)] = false"
                  @change="getDateTo = dataToDateSelected"
                  class="v-date-picker-custom"
                ></v-date-picker>
                <!--  -->
              </v-menu>
            </div>
          </div>
          <!-- End date picker -->
        </div>
        <div class="justify-end d-flex align-center">
          <span class="text-12px mt-1 color-title">総計</span>
          <span class="text-18px">{{ getTotalContractList }}</span>
          <span class="text-12px mt-1">{{ $t('commons.item') }}</span>
        </div>
      </div>
      <v-card>
        <Table
          :attr="{
            dense: true,
            'no-data-text': $t('rules.noData'),
            'item-key': 'id',
          }"
          ref="table"
          :itemsPerPage="50"
          :itemsPerPageOptions="[50, 100, 200, 500]"
          :headers="headers"
          :funReset="reloadData"
          :total="getTotalContractList"
          :items="getContractList"
          :multiSort="true"
          :sortField="listSortField"
        >
          <template v-slot:[`item.productType.code`]="{ item }">
            {{ item.productType ? item.productType.code : '' }}
          </template>
          <template v-slot:[`item.client.memberId`]="{ item }">
            {{ item.client ? item.client.memberId : '' }}
          </template>
          <template v-slot:[`item.client.name`]="{ item }">
            {{ item.client ? item.client.name : '' }}
          </template>
          <template v-slot:[`item.client.nameKana`]="{ item }">
            {{ item.client ? item.client.nameKana : '' }}
          </template>
          <template v-slot:[`item.paymentDate`]="{ item }">
            {{ item.paymentDate | formatDate }}
          </template>
          <template v-slot:[`item.receivedPayment`]="{ item }">
            {{ item.receivedPayment | toThousands }}
          </template>
          <template v-slot:[`item.purchasePaymentMethod.name`]="{ item }">
            {{
              item.purchasePaymentMethod ? item.purchasePaymentMethod.name : ''
            }}
          </template>
          <template v-slot:[`item.receivedPaymentDifference`]="{ item }">
            {{
              getReceivedPaymentDifference(item) | toThousands
            }}
          </template>
          <template v-slot:[`item.accountingCheck`]="{ item }">
            {{ item.accountingCheck ? item.accountingCheck : '' }}
          </template>
          <template v-slot:[`item.paymentTotalWithTax`]="{ item }">
            {{ item.paymentTotalWithTax | toThousands }}
          </template>
          <template v-slot:[`item.entranceFee`]="{ item }">
            {{ item.entranceFee | toThousands }}
          </template>
          <template v-slot:[`item.entranceFeePlusTax`]="{ item }">
            {{
              (item.entranceFee === null && item.entranceFeeTax === null ? null :
              (item.entranceFee + item.entranceFeeTax)) | toThousands
            }}
          </template>
          <template v-slot:[`item.annualFee`]="{ item }">
            {{ item.annualFee | toThousands }}
          </template>
          <template v-slot:[`item.salesStaff.name`]="{ item }">
            {{ item.salesStaff ? item.salesStaff.name : '' }}
          </template>
          <template v-slot:[`item.ad`]="{ item }">
            <v-btn icon class="t-btn--prm" :to="getUrl(item)" target="_blank">
              <v-icon>mdi-card-account-details</v-icon>
            </v-btn>
          </template>
        </Table>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Table from '@/components/Table/index.vue';
import { getReceivedPaymentDifference } from '@/utils/contract'

export default {
  name: 'ContractList',
  components: {
    Table,
  },
  data() {
    return {
      listSortField: [
        {
          colName: 'productType.code', // === value in headers
          name: 'productType.code',
          fieldName: '',
        },
        {
          colName: 'client.memberId', // === value in headers
          name: 'client.memberId',
          fieldName: '',
        },
      ],
      tab: null,
      menuDate: [],
      menuDateTo: [],
      checkbox: '',
      listDate: [{ id: 1, date: new Date().toISOString().substr(0, 10) }],
      listDateTo: [{ id: 2, date: new Date().toISOString().substr(0, 10) }],
      contractName: '',
      headers: [
        {
          text: '契約 商品',
          value: 'productType.code',
          sortable: true,
          id: 'id',
        },
        {
          text: '契約番号',
          value: 'contractNumber',
          sortable: false,
          id: 'id',
        },
        {
          text: '会員番号',
          value: 'client.memberId',
          sortable: true,
          id: 'id',
        },
        {
          text: '顧客名',
          value: 'client.name',
          sortable: false,
          id: 'id',
        },
        {
          text: '顧客名フリガナ',
          value: 'client.nameKana',
          sortable: false,
          id: 'id',
        },
        {
          text: '入金日',
          value: 'paymentDate',
          sortable: false,
          id: 'id',
        },
        {
          text: '入金金額',
          value: 'receivedPayment',
          sortable: false,
          id: 'id',
        },
        {
          text: '入金種別',
          value: 'purchasePaymentMethod.name',
          sortable: false,
          id: 'id',
        },
        {
          text: '入金額 差異',
          value: 'receivedPaymentDifference',
          sortable: false,
          id: 'id',
        },
        {
          text: '経理チェック',
          value: 'accountingCheck',
          sortable: false,
          id: 'id',
        },
        {
          text: '支払総額 (税込)',
          value: 'paymentTotalWithTax',
          sortable: false,
          id: 'id',
        },
        {
          text: '入会金 (税抜)',
          value: 'entranceFee',
          sortable: false,
          id: 'id',
        },
        {
          text: '施設名',
          value: 'computedFacility.facilityName',
          sortable: false,
          id: 'id',
        },
        {
          text: '経理伝達事項',
          value: 'accountingNotes',
          sortable: false,
          id: 'id',
        },
        {
          text: '販売担当者',
          value: 'salesStaff.name',
          sortable: false,
          id: 'id',
        },
        {
          text: '契約 詳細',
          value: 'ad',
          sortable: false,
          id: 'id',
        },
      ],
      facilities: [],
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
    };
  },
  mounted() {
    this.$refs.table.reset();
  },
  computed: {
    ...mapGetters([
      'getContractList',
      'getPaymentDateFromSelected',
      'getAccountingCheck',
      'getPaymentDateToSelected',
      'getSkipContractList',
      'getTotalContractList',
      'getTakeContractList',
    ]),
    datafromDateSelected: {
      get() {
        return this.getPaymentDateFromSelected;
      },
      set(value) {
        this.setPaymentDateFromSelected(value);
        this.$refs.table.reset();
      },
    },
    dataToDateSelected: {
      get() {
        return this.getPaymentDateToSelected;
      },
      set(value) {
        this.setPaymentDateToSelected(value);
        this.$refs.table.reset();
      },
    },
    accountingChecked: {
      get() {
        return this.getAccountingCheck;
      },
      set(value) {
        this.setAccountingCheck(value);
        this.$refs.table.reset();
      },
    },
    getDateFrom: {
      get() {
        return this.getPaymentDateFromSelected;
      },
      set(value) {
        this.setPaymentDateFromSelected(value);
        this.$refs.table.reset();
      },
    },
    getDateTo: {
      get() {
        return this.getPaymentDateToSelected;
      },
      set(value) {
        this.setPaymentDateToSelected(value);
        this.$refs.table.reset();
      },
    },
  },
  methods: {
    ...mapActions(['contractUndigestedList']),
    ...mapMutations([
      'setPaymentDateSelected',
      'setAccountingCheck',
      'setPaymentDateFromSelected',
      'setPaymentDateToSelected',
      'setPermissionUser',
      'setRoleAdminUser',
      'setSkipContractList',
      'setTakeContractList',
    ]),
    async reloadData(variables) {
      if (variables) {
        this.setTakeContractList(variables.pagination.take);
        this.setSkipContractList(variables.pagination.skip);
        await this.contractUndigestedList(variables.orderBy);
      }
    },
    getUrl(item) {
      var url = '';
      if (item.productType.code === 'VS') {
        url = '/contract/vs-vs';
      } else if (item.productType.code === 'PW') {
        url = '/contract/usage-situation-sp-pw';
      } else if (item.productType.code === 'MW') {
        url = '/contract/usage-situation-mw';
      } else if (item.productType.code === 'VM') {
        url = '/contract/usage-situation-vm';
      } else {
        url = '/contract/usage-situation-sp-pw';
      }
      // return `${url}/${item.client.id}/${item.productType.code}`;
      return `${url}/${item.id}/${item.productType.code}`;
      // const routeData = this.$router.resolve({path: `${url}/${item.id}/${item.productType.code}`});
      // window.open(routeData.href, '_blank');
    },
    getReceivedPaymentDifference
  },
};
</script>

<style lang="scss" scoped>
h5 {
  font-size: 14px !important;
  color: #000000 !important;
  font-weight: 500;
}
.tab-custom {
  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__tab {
    width: 100%;
    max-width: 810px;
    display: flex;
  }
  .btn-add-custom {
    align-self: center;
  }
}
.cardCustome {
  border: 1px solid white;
}
.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}
.v-data-table::v-deep {
  th,
  .v-select__selection {
    font-size: 14px !important;
    color: #757575 !important;
    font-weight: bold;
    white-space: nowrap;
  }
  .v-data-table-header {
    .text-start .sortable {
      display: flex !important;
      justify-content: space-between;
      span {
        width: 50%;
      }
    }
  }
  .v-icon__svg {
    color: #000;
  }

  tbody {
    tr {
      td {
        color: #757575 !important;
        font-size: 16px !important;
        white-space: nowrap;
      }
    }
  }
  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    .theme--light.v-input {
      .v-input__control {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 13px !important;
        }
      }
    }
    .v-icon__svg {
      color: #333333 !important;
    }
  }
  .v-data-footer__pagination {
    display: none !important;
  }
}

::v-deep {
  .v-input--checkbox {
    .v-input__control {
      .v-input__slot {
        .theme--light.v-label {
          font-size: 18px !important;
          color: #666666 !important;
          font-weight: 400;
        }
      }
    }
  }
  .v-input__control {
    .v-input__slot {
      .v-text-field__slot .theme--light.v-label {
        font-size: 14px !important;
        color: #888888 !important;
        font-weight: 500;
      }
    }
  }
  .mdi-calendar-month {
    color: #333333 !important;
  }
  .color-title {
    font-weight: 500 !important;
    color: #000 !important;
  }
  .theme--light.v-card {
    color: #0b6786 !important;
    font-weight: 500 !important;
  }
  .v-tab--active .v-tab {
    color: #13ace0 !important;
  }
  .v-input__icon--clear {
    .v-icon__svg {
      height: 15px !important;
      width: 15px !important;
    }
  }
}
</style>
