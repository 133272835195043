<template>
  <div>
    <v-card max-width="1200px" class="mx-10 cardCustome" outlined>
      <div class="d-flex justify-space-start mb-3">
        <div class="d-flex align-center text--small">
          期限終了月
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-model="dateSelected"
                v-on="on"
                small
                outlined
                height="24"
                width="60"
                color="var(--text_gray)"
                class="btn-date bg--white mr-2 ml-2"
              >
                {{ dateSelected.split('-').join('/') }}
              </v-btn>
            </template>
            <v-date-picker
              v-model="dateSelected"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              type="month"
              @input="menuDate = false"
              @change="changeDateFrom"
            ></v-date-picker>
          </v-menu>
          ~
          <v-menu
            v-model="menuDateTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-model="dateToSelected"
                v-on="on"
                small
                outlined
                height="24"
                width="60"
                color="var(--text_gray)"
                class="btn-date bg--white mr-5 ml-2"
              >
                {{ dateToSelected.split('-').join('/') }}
              </v-btn>
            </template>
            <v-date-picker
              v-model="dateToSelected"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              type="month"
              @input="menuDateTo = false"
              @change="changeDateTo"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="d-flex align-center">
          <label class="text--small">契約商品</label>
          <v-select
            :items="productTypeList"
            v-model="productTypeSelected"
            item-text="text"
            item-value="value"
            dense
            outlined
            placeholder="来店"
            hide-details
            class="ml-2 mr-4 v-select-custom"
            @change="
              () => {
                $refs.table.resetPageAndSearch();
              }
            "
          ></v-select>
        </div>
        <span class="num-of-case ml-auto text--small"
          >総計
          <span v-if="isMwRights">{{ total1 }}</span>
          <span v-else>{{ total }}</span>
          <span>{{ $t('commons.item') }}</span></span
        >
      </div>
      <v-btn
        @click="showAlert()"
        outlined
        small
        color="var(--text_gray)"
        class="btn-crm-primary mb-2"
        hide-details
        >選択項目の顧客番号をクリップボードにコピー</v-btn
      >
      <Table
        :attr="{
          scrollable: true,
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'idx',
        }"
        v-if="isMwRights"
        ref="table"
        :itemsPerPage="5"
        :itemsPerPageOptions="[5, 10, 20, 50, 100, 200, 500]"
        :headers="headers"
        :total="total1"
        :items="expiringMwRightsList"
        :showSelect="true"
        :funReset="fetchData"
        @setSelected="
          value => {
            this.selected = value;
          }
        "
      >
        <template v-slot:[`item.expireDate`]="{ item }">
          {{ item.expireDate.split('-').join('/') }}
        </template>
        <template v-slot:[`item.year`]="{ item }"> {{ item.year }}年 </template>
        <template
          v-slot:[`item.contract.mwPwRoomType.facility.name`]="{ item }"
        >
          {{
            item.contract.mwPwRoomType &&
              item.contract.mwPwRoomType.facility &&
              item.contract.mwPwRoomType.facility.name
          }}
        </template>
        <template v-slot:[`item.contract.mwPwWeekNumber`]="{ item }">
          {{
            item.contract.mwPwWeekNumber
              ? item.contract.mwPwWeekNumber + '週'
              : ''
          }}
        </template>
        <template v-slot:[`item.contract.mwRank`]="{ item }">
          {{ item.contract.mwRank ? item.contract.mwRank : '' }}
        </template>
        <template v-slot:[`item.contractDetails`]="{ item }">
          <v-btn
            icon
            class="t-btn--prm"
            :to="
              `/contract/usage-situation-mw/${item.contract.id}/${item.contract.productType.code}?clientId=${item.contract.client.id}&contractId=${item.contract.id}`
            "
            target="_blank"
          >
            <v-icon>mdi-card-account-details</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.contract.client.isCompany`]="{ item }">
          {{ item.contract.client.isCompany ? '法人' : '個人' }}
        </template>
        <template v-slot:[`item.contract.client.memberId`]="{ item }">
          {{
            item.contract.client.memberId ? item.contract.client.memberId : ''
          }}
        </template>
        <template v-slot:[`item.customerDetails`]="{ item }">
          <v-btn
            icon
            class="t-btn--prm"
            :to="
              `customer-details/${item.contract.client.id}?typeClient=${item.contract.client.type}`
            "
            target="_blank"
          >
            <v-icon>mdi-human-handsup</v-icon>
          </v-btn>
        </template>
      </Table>
      <Table
        :attr="{
          scrollable: true,
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'idx',
        }"
        v-else
        ref="table"
        :itemsPerPage="5"
        :itemsPerPageOptions="[5, 10, 20, 50, 100, 200, 500]"
        :headers="headersPro"
        :total="total"
        :items="expiringContractPointsList"
        :showSelect="true"
        :funReset="fetchData"
        @setSelected="
          value => {
            this.selected = value;
          }
        "
      >
        <template v-slot:[`item.validThru`]="{ item }">
          {{ item.validThru.split('-').join('/') }}
        </template>
        <template v-slot:[`item.pointRemains`]="{ item }">
          {{ item.pointRemains | toThousands }}pt
        </template>
        <template v-slot:[`item.year`]="{ item }"> {{ item.year }}年 </template>
        <template v-slot:[`item.contract.basePoint`]="{ item }">
          {{ item.contract.basePoint && item.contract.basePoint + 'pt' }}
        </template>
        <template v-slot:[`item.contractDetails`]="{ item }">
          <v-btn
            icon
            class="t-btn--prm"
            :to="
              `/contract/usage-situation-sp-pw/${item.contractId}/${item.contract.productType.code}?clientId=${item.contract.client.id}&contractId=${item.contractId}`
            "
            target="_blank"
          >
            <v-icon>mdi-card-account-details</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.contract.client.isCompany`]="{ item }">
          {{ item.contract.client.isCompany ? '法人' : '個人' }}
        </template>
        <template v-slot:[`item.contract.client.memberId`]="{ item }">
          {{
            item.contract.client.memberId ? item.contract.client.memberId : ''
          }}
        </template>
        <template v-slot:[`item.customerDetails`]="{ item }">
          <v-btn
            icon
            class="t-btn--prm"
            :to="
              `customer-details/${item.contract.client.id}?typeClient=${item.contract.client.type}`
            "
            target="_blank"
          >
            <v-icon>mdi-human-handsup</v-icon>
          </v-btn>
        </template>
      </Table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { addMonths } from '@/utils/dateUtil';
import Table from '@/components/Table/index.vue';

export default {
  name: 'UndigestedContract',
  data() {
    return {
      selected: [],
      total: 0,
      total1: 0,
      productTypeList: [
        {
          text: 'MW',
          value: 1,
        },
        {
          text: 'SP',
          value: 2,
        },
        {
          text: 'FP',
          value: 3,
        },
      ],
      productTypeSelected: 1,
      tab: null,
      menuDate: false,
      menuDateTo: false,
      dateSelected: new Date().toISOString().substr(0, 7),
      dateToSelected: addMonths(
        new Date().toISOString().substr(0, 10),
        1,
      ).substr(0, 7),
      headers: [
        {
          text: 'デポジット失効日',
          value: 'expireDate',
        },
        {
          text: 'デポジット種別',
          value: 'depositType',
        },
        {
          text: 'MW年度',
          value: 'year',
        },
        {
          text: '権利施設',
          value: 'contract.mwPwRoomType.facility.name',
        },
        {
          text: '週',
          value: 'contract.mwPwWeekNumber',
        },
        {
          text: 'R',
          value: 'contract.mwRank',
        },
        {
          text: '契約詳細',
          value: 'contractDetails',
          align: 'center',
          sortable: false,
        },
        {
          text: '個人/法人',
          value: 'contract.client.isCompany',
        },
        {
          text: '顧客名',
          value: 'contract.client.name',
        },
        {
          text: '会員番号',
          value: 'contract.client.memberId',
        },
        {
          text: '顧客詳細',
          value: 'customerDetails',
          align: 'center',
          sortable: false,
        },
      ],
      headersPro: [
        {
          text: 'ポイント期限日',
          value: 'validThru',
        },
        {
          text: '残ポイント',
          value: 'pointRemains',
        },
        {
          text: '年度',
          value: 'year',
        },
        {
          text: '年間ポイント数(SP・FP)',
          value: 'contract.basePoint',
        },
        {
          text: '契約詳細',
          value: 'contractDetails',
          align: 'center',
          sortable: false,
        },
        {
          text: '個人/法人',
          value: 'contract.client.isCompany',
        },
        {
          text: '顧客名',
          value: 'contract.client.name',
        },
        {
          text: '会員番号',
          value: 'contract.client.memberId',
        },
        {
          text: '顧客詳細',
          value: 'customerDetails',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$refs.table.reset();
  },
  computed: {
    ...mapGetters(['expiringContractPointsList', 'expiringMwRightsList']),
    selectedClientIds () {
      return [...new Set(this.selected.map(item => item.contract.client.id))]
    },
    isMwRights () {
      return this.productTypeSelected === 1
    }
  },
  methods: {
    addMonths,
    changeDateFrom(v) {
      if (new Date(v).getTime() > new Date(this.dateToSelected).getTime()) {
        this.dateToSelected = addMonths(
          new Date(v).toISOString().substr(0, 10),
          1,
        ).substr(0, 7);
      }
      this.$refs.table.reset();
    },
    changeDateTo(v) {
      if (new Date(v).getTime() < new Date(this.dateSelected).getTime()) {
        this.dateSelected = addMonths(
          new Date(v).toISOString().substr(0, 10),
          -1,
        ).substr(0, 7);
      }
      this.$refs.table.reset();
    },

    showAlert() {
      const clientIds = this.selectedClientIds.join(', ')
      navigator.clipboard.writeText(clientIds)
      this.$swal({
        text: '顧客番号をコピーしました',
      });
    },

    async fetchData(variables) {
      this.$refs.table.unselect()
      if (variables) {
        const fromYear = this.dateSelected.split('-')[0];
        const fromMonth = this.dateSelected.split('-')[1];
        const toYear = this.dateToSelected.split('-')[0];
        const toMonth = this.dateToSelected.split('-')[1];
        if (this.isMwRights) {
          await this.fetchExpiringMwRightsList({
            year: fromYear,
            month: fromMonth,
            toYear: toYear,
            toMonth: toMonth,
            skip: variables.pagination.skip,
            take: variables.pagination.take,
          }).then(result => {
            this.total1 = result;
          });
        } else {
          await this.fetchExpiringContractPointsList({
            year: fromYear,
            month: fromMonth,
            toYear: toYear,
            toMonth: toMonth,
            type: this.productTypeSelected,
            skip: variables.pagination.skip,
            take: variables.pagination.take,
          }).then(result => {
            this.total = result;
          });
        }
      }
    },
    ...mapActions([
      'fetchExpiringContractPointsList',
      'fetchExpiringMwRightsList',
    ]),
  },
  components: {
    Table,
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-select-custom {
    max-width: 50px !important;
  }
  .btn-date * {
    font-size: 10px !important;
  }
  .btn-date {
    .v-btn__content {
      color: #000 !important;
    }
  }
}
.tab-custom {
  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__tab {
    width: 100%;
    max-width: 810px;
    display: flex;
  }
  .btn-add-custom {
    align-self: center;
  }
}
.cardCustome {
  border: 1px solid white;
}
.text--small {
  color: #000 !important;
  font-weight: 500;
}
</style>
