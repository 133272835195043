<template>
  <div>
    <v-card-title v-once class="mt-n5 page-title-sub card-title">
      <v-row style="background-color: #f8f8f8">
        <v-col :cols="10" class="pb-0">
          <h1
            class="mx-12 my-4 page-title-list"
            style="margin-left: 30px !important"
          >
            {{ $t('contract.routeName.contract') }}
          </h1>
        </v-col>
      </v-row>
    </v-card-title>

    <v-tabs v-model="tab" align-with-title class="response-tab my-4">
      <v-tabs-slider color="#13ACE0"></v-tabs-slider>
      <v-tab v-for="item in tabNames" :key="item" class="page-title-tab">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="py-2">
      <v-tab-item v-for="item in tabNames" :key="item">
        <contractAll v-if="tab === 0"></contractAll>
        <contractUndigestedList v-if="tab === 1"></contractUndigestedList>
        <undigested-contract v-if="tab === 2"></undigested-contract>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import contractAll from './contractAll';
import contractUndigestedList from './contractUndigestedList';
import UndigestedContract from './UndigestedContract.vue';

export default {
  name: 'ContractTab',
  data() {
    return {
      checkbox: false,
      tab: 0,
      tabNames: [
        this.$t('contract.contractTab.all'),
        '当月経理未チェック契約',
        '権利未消化契約',
      ],
    };
  },
  components: {
    contractAll,
    contractUndigestedList,
    UndigestedContract,
  },
};
</script>

<style lang="scss" scoped>
.response-tab::v-deep {
  .v-tabs-bar {
    height: 35px;
  }

  .v-tab {
    color: #13ace0 !important;
    width: 205px;
    height: 35px;
    text-align: center;
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    border-left: solid 1px #ccc;

    &:last-child {
      border-right: solid 1px #ccc;
    }

    &--active {
      background-color: #13ace0 !important;
      color: #fff !important;
      border-color: #13ace0 !important;
    }
  }

  .v-tabs-slider-wrapper,
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none;
  }
}
::v-deep{
  .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
    color: #13ACE0 !important;
  }
}
</style>
