var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mr-16 ml-10 cardCustome",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"justify-start d-flex"},[_c('div',{staticClass:"d-flex align-center text-12px"},[_vm._v(" 契約商品 "),_c('v-select',{staticClass:"mx-5 v-select-custom",attrs:{"items":_vm.listName,"multiple":"","item-text":"code","item-value":"id","dense":"","outlined":"","hide-details":"","label":"指定なし","single-line":""},model:{value:(_vm.productTypeIds),callback:function ($$v) {_vm.productTypeIds=$$v},expression:"productTypeIds"}})],1),_c('v-checkbox',{attrs:{"label":"満了済みの契約も表示","disabled":!_vm.checkPerUser},model:{value:(_vm.ended),callback:function ($$v) {_vm.ended=$$v},expression:"ended"}})],1),_c('div',{staticClass:"justify-end d-flex align-center"},[_c('span',{staticClass:"text-12px mt-1 color-title"},[_vm._v("総計")]),_c('span',{staticClass:"text-18px"},[_vm._v(_vm._s(_vm.getTotalContractAll))]),_c('span',{staticClass:"text-12px mt-1"},[_vm._v(_vm._s(_vm.$t('commons.item')))])])]),_c('v-card',[_c('Table',{ref:"table",attrs:{"attr":{
          'server-items-length': _vm.getTotalContractAll,
          dense: true,
          'no-data-text': _vm.$t('rules.noData'),
          'item-key': 'id',
          dense: true,
        },"itemsPerPage":50,"itemsPerPageOptions":[50, 100, 200, 500],"headers":_vm.headers,"total":_vm.getTotalContractAll,"items":_vm.getContractAll,"filter":_vm.filter,"funReset":_vm.getContractAllData,"sortField":_vm.listSortField},scopedSlots:_vm._u([{key:"item.CustomerDetails",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"t-btn--prm",attrs:{"icon":"","to":{
              name: 'customer-details',
              params: { id: item.client.id },
              query: {
                typeClient: item.client.type,
              },
            },"target":"_blank"}},[_c('v-icon',[_vm._v(" mdi-human-handsup")])],1)]}},{key:"item.contract",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"t-btn--prm",attrs:{"icon":""},on:{"click":function($event){return _vm.getUrl(item)}}},[_c('v-icon',[_vm._v("mdi-card-account-details")])],1)]}},{key:"item.client.isCompany",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.isCompany ? '法人' : ' 個人')+" ")]}},{key:"item.productType.code",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.productType.code ? item.productType.code : '')+" ")]}},{key:"item.client.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.name ? item.client.name : '')+" ")]}},{key:"item.client.pic.name",fn:function(ref){
            var item = ref.item;
return [(item.client)?_c('span',[_vm._v(" "+_vm._s(item.client.pic ? item.client.pic.name : '')+" ")]):_vm._e()]}},{key:"item.years",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.years)+" 年 ")]}},{key:"item.endDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.endDate))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }