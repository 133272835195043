<template>
  <div>
    <v-card class="mr-16 ml-10 cardCustome" outlined>
      <div class="d-flex justify-space-between">
        <div class="justify-start d-flex">
          <div class="d-flex align-center text-12px">
            契約商品
            <v-select
              :items="listName"
              v-model="productTypeIds"
              multiple
              item-text="code"
              item-value="id"
              dense
              outlined
              hide-details
              label="指定なし"
              single-line
              class="mx-5 v-select-custom"
            ></v-select>
          </div>
          <v-checkbox
            v-model="ended"
            label="満了済みの契約も表示"
            :disabled="!checkPerUser"
          ></v-checkbox>
        </div>

        <div class="justify-end d-flex align-center">
          <span class="text-12px mt-1 color-title">総計</span>
          <span class="text-18px">{{ getTotalContractAll }}</span>
          <span class="text-12px mt-1">{{ $t('commons.item') }}</span>
        </div>
      </div>
      <v-card>
        <Table
          :attr="{
            'server-items-length': getTotalContractAll,
            dense: true,
            'no-data-text': $t('rules.noData'),
            'item-key': 'id',
            dense: true,
          }"
          ref="table"
          :itemsPerPage="50"
          :itemsPerPageOptions="[50, 100, 200, 500]"
          :headers="headers"
          :total="getTotalContractAll"
          :items="getContractAll"
          :filter="filter"
          :funReset="getContractAllData"
          :sortField="listSortField"
        >
          <template v-slot:[`item.CustomerDetails`]="{ item }">
            <v-btn
              icon
              class="t-btn--prm"
              :to="{
                name: 'customer-details',
                params: { id: item.client.id },
                query: {
                  typeClient: item.client.type,
                },
              }"
              target="_blank"
            >
              <v-icon> mdi-human-handsup</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.contract`]="{ item }">
            <v-btn icon class="t-btn--prm" @click="getUrl(item)">
              <v-icon>mdi-card-account-details</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.client.isCompany`]="{ item }">
            {{ item.client.isCompany ? '法人' : ' 個人' }}
          </template>
          <template v-slot:[`item.productType.code`]="{ item }">
            {{ item.productType.code ? item.productType.code : '' }}
          </template>
          <template v-slot:[`item.client.name`]="{ item }">
            {{ item.client.name ? item.client.name : '' }}
          </template>
          <template v-slot:[`item.client.pic.name`]="{ item }">
            <span v-if="item.client">
              {{ item.client.pic ? item.client.pic.name : '' }}
            </span>
          </template>
          <template v-slot:[`item.years`]="{ item }">
            {{ item.years }} 年
          </template>
          <template v-slot:[`item.endDate`]="{ item }">
            {{ item.endDate | formatDate }}
          </template>
        </Table>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  ENUM_PRODUCT_TYPE_LIST,
  CONTRACT_LIST,
} from '@/api/graphql/contract/contractAll/contractAll.js';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import gql from 'graphql-tag';
import Table from '@/components/Table/index.vue';

export default {
  name: 'ContractList',
  components: {
    Table,
  },
  data() {
    return {
      listSortField: [
        {
          colName: 'status', // === value in headers
          name: 'status',
          fieldName: 'pic'
        },
        {
          colName: 'productType.code', // === value in headers
          name: 'productType.code',
          fieldName: ''
        },
        {
          colName: 'contractNumber', // === value in headers
          name: 'contractNumber',
          fieldName: 'contract'
        },
        {
          colName: 'phase.name', // === value in headers
          name: 'phase.name',
          fieldName: ''
        },
        {
          colName: 'endDate', // === value in headers
          name: 'endDate',
          fieldName: 'contract'
        },
        {
          colName: 'years', // === value in headers
          name: 'years',
          fieldName: 'contract'
        },
        {
          colName: 'client.isCompany', // === value in headers
          name: 'client.isCompany',
          fieldName: 'contract'
        },
        {
          colName: 'client.name', // === value in headers
          name: 'client.name',
          fieldName: 'contract'
        },
        {
          colName: 'client.pic.name', // === value in headers
          name: 'client.pic.name',
          fieldName: 'contract'
        }
      ],
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      productTypeIds: [],
      ended: false,
      getContractAll: [],
      headers: [
        {
          text: this.$t('contract.contrac_all_UndigestedList.status'),
          value: 'status',
          sortable: false,
          id: 'id',
          width: '120px',
        },
        {
          text: this.$t('contract.contrac_all_UndigestedList.ContractProduct'),
          value: 'productType.code',
          sortable: true,
          id: 'id',
          width: '110px',
        },
        {
          text: this.$t('contract.contrac_all_UndigestedList.ContractNumber'),
          value: 'contractNumber',
          sortable: false,
          id: 'id',
          width: '180px',
        },
        {
          text: this.$t('contract.contrac_all_UndigestedList.Phase'),
          value: 'phase.name',
          sortable: false,
          id: 'id',
          width: '160px',
        },
        {
          text: '契約満了日',
          value: 'endDate',
          sortable: false,
          id: 'id',
          width: '130px',
        },
        {
          text: '契約年数',
          value: 'years',
          sortable: false,
          id: 'id',
          width: '110px',
        },
        {
          text: '契約詳細',
          value: 'contract',
          sortable: false,
          id: 'id',
          width: '110px',
          align: 'center'
        },
        {
          text: '個人/法人',
          value: 'client.isCompany',
          // align: 'center',
          sortable: false,
          id: 'id',
          width: '120px',
        },
        {
          text: this.$t('contract.contrac_all_UndigestedList.CustomerName'),
          value: 'client.name',
          sortable: false,
          id: 'id',
          width: '200px',
        },
        {
          text: '営業担当者',
          value: 'client.pic.name',
          sortable: false,
          id: 'id',
          width: '140px',
        },
        {
          text: this.$t('contract.contrac_all_UndigestedList.CustomerDetails'),
          value: 'CustomerDetails',
          align: 'center',
          sortable: false,
          id: 'id',
          width: '110px',
        },
      ],
      listName: [],
    };
  },
  mounted() {
    this.enumProductTypeList();
    this.getDataEnum();
  },
  computed: {
    ...mapGetters([
      'getEnumProductTypeList',
      'getTakeContractAll',
      'getSkipContractAll',
      'getTotalContractAll'
    ]),
    filter() {
      return {
        productTypeIds: this.productTypeIds,
        ended: this.ended && null, // if true send null
      }
    },
  },
  watch: {
    filter: {
      deep: true,
      async handler() {
        await this.$nextTick()
        await this.$refs.table.reset();
      }
    }
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions(['contractAll', 'enumProductTypeList']),
    ...mapMutations([
      'setTotalContractAll',
      'setCopyErrorText',
      'setEnumProductTypeList',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
      'setTakeContractAll',
      'setSkipContractAll'
    ]),

    getUrl(item) {
      let url = '';
      // const url = '/contract/basic-infomation';
      if (item.productType.code === 'VS') {
        url = '/contract/vs-vs';
      } else if (item.productType.code === 'PW') {
        url = '/contract/usage-situation-sp-pw';
      } else if (item.productType.code === 'MW') {
        url = '/contract/usage-situation-mw';
      } else if (item.productType.code === 'VM') {
        url = '/contract/usage-situation-vm';
      } else {
        url = '/contract/usage-situation-sp-pw';
      }
      const routeData = `${url}/${item.id}/${item.productType.code}?clientId=${item.clientId}&contractId=${item.id}`;
      window.open(routeData, '_blank');
    },

    async getContractAllData(variables) {
      await this.$apollo
        .query({
          query: gql`
            ${CONTRACT_LIST}
          `,
          variables: variables,
          fetchPolicy: 'no-cache',
        })
        .then((data) => {
          this.getContractAll = data.data.contractList.items;
          this.setTotalContractAll(data.data.contractList.total);
        })
        .catch(async (error) => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              CONTRACT_LIST,
              variables,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    async getDataEnum() {
      await this.$apollo
        .query({
          query: gql`
            ${ENUM_PRODUCT_TYPE_LIST}
          `,
        })
        .then((data) => {
          this.listName = data.data.enumProductTypeList;
        })
        .catch(async (error) => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              ENUM_PRODUCT_TYPE_LIST,
              null,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.justify-start {
  .text-12px {
    color: #000 !important;
    font-weight: 500;
  }
}
.v-menu__content .theme--light .menuable__content__active {
  width: 199px !important;
  min-width: auto !important;
  max-width: none !important;
}
::v-deep {
  .v-data-table-header__icon {
    color: #000 !important;
  }
  .v-text-field--outlined.v-input--dense .v-label {
    top: 2px !important;
    font-weight: 500;
    font-size: 10px;
    color: #000 !important;
  }
  .theme--light.v-label {
    color: #666666 !important;
    font-weight: 400;
  }
  .theme--light.v-card {
    color: #0b6786 !important;
    font-weight: 500 !important;
  }
  .color-title {
    font-weight: 500 !important;
    color: #000 !important;
  }
}
.v-select-custom {
  max-width: 140px !important;
}
.v-data-table::v-deep {
  th,
  .v-select__selection {
    font-size: 14px !important;
    color: #757575 !important;
    font-weight: bold;
  }
  tbody {
    tr {
      td {
        color: #757575 !important;
        font-size: 16px !important;
      }
    }
  }
  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    .theme--light.v-input {
      .v-input__control {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 12px !important;
        }
      }
    }
    .v-icon__svg {
      color: #333333 !important;
    }
  }
  .v-data-footer__pagination {
    display: none !important;
  }
}

.tab-custom {
  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__tab {
    width: 100%;
    max-width: 810px;
    display: flex;
  }
  .btn-add-custom {
    align-self: center;
  }
}
.cardCustome {
  border: 1px solid white;
  max-width: 1500px;
}
</style>
