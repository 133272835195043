export function getReceivedPaymentDifference(contract) {
  if (contract) {
    const {
      receivedPayment,
      paymentTotalWithTax
    } = contract;

    if (typeof receivedPayment === 'number' && typeof paymentTotalWithTax === 'number') {
      return receivedPayment - paymentTotalWithTax
    }
  }
  return null
}
